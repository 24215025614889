import { Injectable } from "@angular/core";
import {
	distinctUntilChanged,
	fromEvent,
	map,
	merge,
	Observable,
	of,
	shareReplay,
	startWith,
	switchMap,
	tap,
	throttleTime,
	timer,
} from "rxjs";

@Injectable({
	providedIn: "root",
})
export class IdleService {
	constructor() {
		this.listenForSuspend();
	}
	private activityEvents = [
		fromEvent(window, "mousemove"),
		fromEvent(window, "resize"),
		fromEvent(document, "keyup"),
		fromEvent(document, "touchend"),
		fromEvent(document, "visibilitychange"),
	];

	listenForSuspend = (): void => {
		fromEvent(window, "pagehide").subscribe(() => {
			console.log("RideOps closed");
		});

		fromEvent(window, "blur").subscribe(() => {
			console.log("RideOps no longer in focus");
		});

		fromEvent(window, "visibilitychange").subscribe(() => {
			if (document.visibilityState === "hidden") {
				console.log("RideOps hidden");
			} else {
				console.log("RideOps Visible");
			}
		});
	};

	private isInactiveAfterMilliseconds: number = 2.5 * 60000; // 2.5 minutes;

	isInactive$: Observable<boolean> = merge(...this.activityEvents).pipe(
		throttleTime(1000), // Ignore multiple event in the same second.
		switchMap(() =>
			merge(
				// Emit `false` immediately on activity
				of(false),
				// Emit `true` after inactivity threshold
				timer(this.isInactiveAfterMilliseconds).pipe(
					tap(() => console.log("RideOps is idle")),
					map(() => true),
				),
			),
		),
		distinctUntilChanged(),
		startWith(false),
		shareReplay(1), // Use as a "hot" observable.
	);
}
