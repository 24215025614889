import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { filter, map, take } from "rxjs/operators";
import { LocationStateQuery } from "../../configuration/state/location-state.query";
import { SettingsComponent } from "../settings/settings.component";
import { StatisticsComponent } from "../statistics/statistics.component";

@Component({
	selector: "app-menu-bar",
	templateUrl: "./menu-bar.component.html",
	styleUrls: ["./menu-bar.component.scss"],
})
export class MenuBarComponent {
	constructor(
		private router: Router,
		private modalController: ModalController,
		private locationStateQuery: LocationStateQuery,
	) {}

	currentUrl$ = this.router.events
		.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
		.pipe(map((event: NavigationEnd) => event.urlAfterRedirects));

	showNotesIcon$ = this.locationStateQuery.noteTypes$.pipe(
		map((noteTypes) => noteTypes.filter((type) => type.isDeleted === false)),
		map((noteTypes) => noteTypes.length > 0),
	);

	allowNavigation$ = this.locationStateQuery.operatorIsSelected$;
	allowStatisticsForOperator$ = this.locationStateQuery.allowStatisticsForOperator$;

	settings = async () => {
		const allowNavigation = await this.allowNavigation$.pipe(take(1)).toPromise();
		if (allowNavigation !== true) {
			return;
		}

		const settingsModal = await this.modalController.create({
			component: SettingsComponent,
		});

		await settingsModal.present();
	};

	statistics = async () => {
		const allowNavigation = await this.allowNavigation$.pipe(take(1)).toPromise();
		if (allowNavigation !== true) {
			return;
		}

		const statisticsModal = await this.modalController.create({
			component: StatisticsComponent,
			cssClass: "statistics-modal auto-height",
		});

		await statisticsModal.present();
	};
}
