import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { mergeMap, take, tap } from "rxjs/operators";
import { LocationStateQuery } from "../state/location-state.query";
import { OperatorService } from "../services/operator.service";
import { RaygunService } from "../services/raygun.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private locationStateQuery: LocationStateQuery,
		private alertController: AlertController,
		private translocoService: TranslocoService,
		private operatorService: OperatorService,
		private raygunService: RaygunService,
	) {}

	private handleUnauthorized = async (error: HttpErrorResponse, originalRequest: HttpRequest<unknown>) => {
		if (error?.status != 401) {
			return;
		}

		//We dont want to log bad login attempts
		const endpont = error.url.split("/").pop();
		if (endpont == "login") {
			return;
		}

		const currentOperator = await this.locationStateQuery.operator$.firstAsync();
		const headerUserId = originalRequest.headers.get("X-User-Identifier");

		// If the current operator is the same as from the original request, just show unauthorized modal.
		if (headerUserId != null && headerUserId == currentOperator?.id) {
			return await this.showUnauthorizedModalAndClearOperator();
		}

		// If there is a discrepancy between current operator and original request, send the error to RayGun
		this.raygunService.send(new Error("401 error occured"), {
			headerUserId: headerUserId,
			currentOperator: currentOperator?.id,
			httpErrorResponse: error,
		});
	};
	// we dont want to keep spam opening the unauthorized window, if multiple network requests fail.
	private unauthorizedModalVisible = false;
	private showUnauthorizedModalAndClearOperator = async () => {
		if (this.unauthorizedModalVisible) {
			return;
		}

		this.unauthorizedModalVisible = true;

		await this.operatorService.logoutOperator();

		const alert = await this.alertController.create({
			header: this.translocoService.translate("AUTHENTICATION_ERROR_TITLE"),
			message: this.translocoService.translate("AUTHENTICATION_ERROR_MESSAGE"),
			buttons: [this.translocoService.translate("AUTHENTICATION_ERROR_OK")],
		});

		await alert.present();
		await alert.onDidDismiss();

		this.unauthorizedModalVisible = false;
	};

	intercept(request: HttpRequest<unknown>, next: HttpHandler) {
		return this.locationStateQuery.rideOpsKeyAndOperator$.pipe(
			take(1),
			mergeMap(([rideOpsKey, operator]) => {
				// Key isn't set yet, but we still need to load .json via http
				if (rideOpsKey == undefined) {
					return next.handle(request);
				}

				const headers: Record<string, string | string[]> = {
					"X-RideOps-key": rideOpsKey,
					"X-Api-Client": "RideOps/1.0",
				};

				if (operator && request.headers.keys().includes("X-User-Identifier") == false) {
					headers["X-User-Identifier"] = operator.id;
					headers["X-Pin-Code"] = operator.pin;
				}

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const authenticatedRequest: HttpRequest<any> = request.clone({
					setHeaders: headers,
				});

				return next.handle(authenticatedRequest).pipe(
					tap({
						error: async (error: HttpErrorResponse) => {
							await this.handleUnauthorized(error, authenticatedRequest);
						},
					}),
				);
			}),
		);
	}
}
